<template>
  <div class="reply-form">
    <v-icon
      color="inqliGray"
      dense
      class="close-button"
      @click="closeForm"
      v-if="closeForm"
      >mdi-close</v-icon
    >
    <h3>Your Reply</h3>
    <div class="clocker">Time spent: {{ timeString }}</div>
    <div>
      <div>
        <p class="form-item-label">Your greeting</p>
        <inqli-text-area rows="2" v-model="greeting" aria-label="greeting">
          <span
            >Your greeting can impact the recipient's perception of you and it
            sets the tone for your interaction. Go ahead and type a friendly
            greeting here.</span
          >
        </inqli-text-area>
      </div>
      <div>
        <p class="form-item-label">Advice and/or Instruction</p>
        <inqli-text-area
          rows="2"
          :hint="adviceHint"
          persistent-hint
          v-model="advice"
          aria-label="advice"
        >
          <span
            >Include any advice based on your knowledge and experience. Being
            clear and concise is helpful!</span
          >
        </inqli-text-area>
      </div>
      <div>
        <p class="form-item-label">Resources</p>
        <div class="mt-2">
          <v-row class="resource-input-container">
            <v-col md="5" cols="12" class="resource-input-item label">
              <p class="form-item-label">Link</p>
            </v-col>
            <v-col md="6" cols="12" class="resource-input-item label">
              <p class="form-item-label">Title</p>
            </v-col>
          </v-row>
          <div v-for="(item, index) in resources" :key="item.key">
            <resource-input
              :value="resources[index]"
              @input="updateResource($event, index)"
              @remove="removeResourceItem(index)"
            />
          </div>
        </div>
        <div class="resource-add-button" v-show="isResourceValid">
          <text-button :noPadding="true" @click="addResourceItem">
            + Add additional resource</text-button
          >
        </div>
      </div>
      <div class="status-bar-container">
        <div class="status-bar rounded-pill">
          <v-progress-linear
            color="inqliGreen"
            background-color="transparent"
            height="18"
            :value="scoreRatioPercentage"
            class="rounded-pill"
          >
            <span>{{ scoreFeedback }}</span>
          </v-progress-linear>
        </div>
      </div>
      <inqli-button
        width="160px"
        class="mt-8"
        :disabled="!isSubmittable"
        @click="submit"
        :isProcess="isSubmitting"
      >
        Send Reply</inqli-button
      >
    </div>
  </div>
</template>
<script>
import InqliTextArea from "../../../../app/components/inputs/TextArea.vue";
import TextButton from "../../../../app/components/buttons/TextButton.vue";
import Button from "../../../../app/components/buttons/Button.vue";
import ResourceInput from "./ResourceInput.vue";
import { required } from "vuelidate/lib/validators";
import { v4 as uuid } from "uuid";
import { createReply } from "../../../utils/reply-request.js";

export default {
  name: "ReplyForm",
  props: {
    closeForm: {
      type: Function,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  components: {
    "inqli-text-area": InqliTextArea,
    "text-button": TextButton,
    "inqli-button": Button,
    "resource-input": ResourceInput,
  },
  data() {
    return {
      time: 0,
      greeting: "",
      advice: "",
      resources: [this.getInitialResourceItem()],
      timer: null,
      formErrorMessage: "",
      isSubmitting: false,
    };
  },
  validations: {
    advice: {
      required,
      minLength: (value) => value.length >= 25,
    },
  },
  computed: {
    scoreFeedback() {
      switch (this.score) {
        case 0:
          return "REPLY SCORE";
        case 1:
          return "😐 OK";
        case 2:
          return "🙂 NOT BAD";
        case 3:
          return "😃 LOOKING GOOD";
        case 4:
        default:
          return "😍 AWESOME";
      }
    },
    score() {
      let score = 0;
      if (this.greeting.length > 0) score++;
      if (!this.$v.advice.$invalid > 0) score++;
      score += Math.min(this.validResourceItemCount, 2);
      return score;
    },
    scoreRatioPercentage() {
      return Math.round((this.score * 100) / 4);
    },
    validResourceItemCount() {
      return this.resources.filter((item) => item.isValid === true).length;
    },
    adviceHint() {
      if (this.advice.length < 25) {
        const remaining = 25 - this.advice.length;
        const character = remaining === 1 ? "character" : "characters";
        return `You must enter at least ${remaining} more ${character}`;
      }
      return "";
    },
    isSubmittable() {
      const isValid = !this.$v.$invalid;
      // in case the user removes all the resources will remain the first item empty
      if (this.isResourceSubmittable) return isValid;
      return false;
    },
    timeString() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    isResourceValid() {
      return this.resources.every((resource) => resource.isValid);
    },
    isResourceSubmittable() {
      return (
        this.isResourceValid ||
        (this.validResourceItemCount == 0 &&
          this.resources[0].url.length === 0 &&
          this.resources[0].title.length === 0)
      );
    },
    submitFormatResources() {
      return this.resources.map((resource) => {
        return {
          url: resource.url,
          title: resource.title,
        };
      });
    },
  },
  watch: {
    isActive(value) {
      if (value) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },
  methods: {
    removeResourceItem(index) {
      if (this.resources.length === 1) {
        this.resources = [this.getInitialResourceItem()];
      } else {
        this.resources.splice(index, 1);
      }
    },
    updateResource(newValue, index) {
      this.$set(this.resources, index, newValue);
    },
    addResourceItem() {
      if (this.isResourceValid) {
        this.resources.push(this.getInitialResourceItem());
      }
      return;
    },
    getInitialResourceItem() {
      return {
        title: "",
        url: "",
        isValid: false,
        key: uuid(),
      };
    },
    async submit() {
      if (!this.isSubmittable) return;
      clearInterval(this.timer);
      try {
        this.isSubmitting = true;
        const replyBody = {
          greeting: this.greeting,
          advice: this.advice,
          // this one has to be in the right format (only url and title)
          info: this.isResourceValid ? this.submitFormatResources : [],
          elapsed_time: this.time,
        };
        const newReply = await createReply({
          body: replyBody,
          questionId: this.questionId,
        });
        this.$emit("submit:success", newReply);
        this.$store.dispatch("notify", {
          message: "Hooray! Your reply is on the way",
          type: "success",
        });
        this.closeForm();
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while submitting your reply."
        );
      }
      this.isSubmitting = false;
      this.resetForm();
    },
    startTimer() {
      setTimeout(() => {
        this.timer = setInterval(() => {
          this.time += 1;
        }, 1000);
      }, 100);
    },
    stopTimer() {
      this.timer && clearInterval(this.timer);
      this.timer = null;
    },
    resetForm() {
      this.time = 0;
      this.greeting = "";
      this.advice = "";
      this.resources = [this.getInitialResourceItem()];
      this.timer = null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_extends.scss";

.reply-form {
  position: relative;
  padding: 10px;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
}
.form-item-label {
  @extend %input-label;
}
.resource-add-button {
  display: flex;
  justify-content: flex-start;
  margin-top: -10px;
  margin-bottom: -16px;
}
.clocker {
  font-size: 12px;
  font-weight: 700;
  text-align: right;
  padding: 14px 2px;
}
.status-bar {
  margin: auto;
  width: 200px;
  border: 2px solid lighten($inqliGray, 10%);
  padding: 2px;
  margin-top: 16px;
  span {
    color: $inqliNavy;
    font-size: 10px;
    font-weight: 600;
  }
}
</style>
