<template>
  <v-row class="resource-input-container">
    <v-col md="5" cols="12" class="resource-input-item">
      <v-text-field
        aria-label="resourceLink"
        placeholder="Paste your link here"
        class="resource-input"
        v-model="inputValue.url"
      />
    </v-col>
    <v-col md="6" cols="12" class="resource-input-item">
      <v-text-field
        aria-label="resourceTitle"
        placeholder="Edit your title here"
        class="resource-input"
        v-model="inputValue.title"
      />
    </v-col>
    <v-icon
      class="resource-remove-button"
      @click="removeResource"
      aria-label="resource-remove"
      >mdi-close-circle-outline</v-icon
    >
  </v-row>
</template>
<script>
import { url } from "vuelidate/lib/validators";
import { getUrlTitle } from "../../../utils/reply-request";

export default {
  name: "ResourceInput",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputValue: { ...this.value },
    };
  },
  watch: {
    async "inputValue.url"(url) {
      const inputValue = { ...this.inputValue, url };
      const isValid = this.validate(inputValue);
      inputValue.isValid = isValid;
      this.$emit("input", { ...inputValue });
      this.fetchUrlTitle(url);
    },
    "inputValue.title"(title) {
      const inputValue = { ...this.inputValue, title };
      const isValid = this.validate(inputValue);
      inputValue.isValid = isValid;
      this.$emit("input", { ...inputValue });
    },
  },
  methods: {
    removeResource() {
      this.$emit("remove", this.value);
    },
    async fetchUrlTitle(resourceUrl) {
      if (url(resourceUrl) && resourceUrl.length > 0) {
        try {
          const response = await getUrlTitle(resourceUrl);
          const title = response.title ? response.title : "";
          this.inputValue.title = title;
        } catch (e) {
          this.inputValue.title = "";
        }
      }
    },
    validate(inputValue) {
      let isValid = true;
      if (inputValue.title.length <= 0 || inputValue.url.length <= 0) {
        isValid = false;
      } else if (!url(inputValue.url)) {
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
<style lang="scss" scoped>
.resource-input-item {
  padding: 0 12px;
}
.resource-input {
  margin-top: -16px;
  padding-top: 10px;
}
.resource-remove-button {
  margin-left: 10px;
  margin-top: -16px;
}
.resource-remove-button:hover {
  color: $inqliRed;
}
</style>
