var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "question-detail-container", attrs: { flat: "" } },
    [
      _c(
        "span",
        { staticClass: "close-button" },
        [
          _vm.closeCard
            ? _c("inqli-icon", { on: { click: _vm.closeCard } }, [
                _vm._v("mdi-close")
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "question-header" },
        [
          _vm.question.image_url
            ? _c("v-img", {
                staticClass: "question-image",
                attrs: {
                  src: _vm.question.image_url,
                  gradient: "180deg, rgba(0, 0, 0, 0) 0%, #031a4a 52.6%"
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "question-header-info" }, [
            _c("span", { staticClass: "question-type-container" }, [
              _vm._v(_vm._s(_vm.questionType))
            ]),
            _c("h1", { staticClass: "question-title" }, [
              _vm._v(_vm._s(_vm.question.title))
            ]),
            _c(
              "div",
              { staticClass: "question-author" },
              [
                _c(
                  "router-link",
                  { staticClass: "author-link", attrs: { to: _vm.authorLink } },
                  [
                    _c("avatar", {
                      attrs: { size: "medium", image: _vm.author.avatar }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "author-info" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "author-link",
                        attrs: { to: _vm.authorLink }
                      },
                      [
                        _c("span", { staticClass: "author-name" }, [
                          _vm._v(_vm._s(_vm.author.display_name))
                        ])
                      ]
                    ),
                    _vm.author.org
                      ? _c(
                          "span",
                          { staticClass: "author-info-item" },
                          [
                            _c(
                              "v-icon",
                              { attrs: { "x-small": "", color: "inqliGray" } },
                              [_vm._v(" mdi-home-city ")]
                            ),
                            _vm._v(" Works at " + _vm._s(_vm.author.org))
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "question-meta" },
              _vm._l(_vm.questionMetaData, function(item) {
                return _c(
                  "div",
                  { key: item.value, staticClass: "question-meta-item" },
                  [
                    _c("v-icon", { attrs: { color: "white", small: "" } }, [
                      _vm._v(_vm._s(item.icon))
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(_vm._s(item.value))
                    ])
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "question-action" },
              [
                _vm.isAuth
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-on-hover": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          class: {
                                            "question-action-button": true,
                                            "question-action-button-active":
                                              _vm.isLiked
                                          },
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "inqliPurple",
                                            rounded: ""
                                          },
                                          on: { click: _vm.toggleLikedQuestion }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "material-symbols-rounded": true,
                                            fill: _vm.isLiked
                                          }
                                        },
                                        [_vm._v(" favorite ")]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3401371963
                        )
                      },
                      [_c("span", [_vm._v("Like")])]
                    )
                  : _vm._e(),
                _vm.isAuth
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-on-hover": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          class: {
                                            "question-action-button": true,
                                            "question-action-button-active":
                                              _vm.isBookmarked
                                          },
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "inqliPurple",
                                            rounded: ""
                                          },
                                          on: {
                                            click: _vm.toggleBookmarkedQuestion
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "material-symbols-rounded": true,
                                            fill: _vm.isBookmarked
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isBookmarked
                                                  ? "bookmark"
                                                  : "bookmark_add"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3307364556
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.isBookmarked ? "Unbookmark" : "Bookmark")
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "question-action-button",
                    attrs: {
                      outlined: "",
                      small: "",
                      color: "inqliPurple",
                      rounded: ""
                    }
                  },
                  [
                    _c("share-link-dialog", {
                      attrs: { small: true, shareUrl: _vm.shareUrl }
                    })
                  ],
                  1
                ),
                _vm.isAuth
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "question-action-primary",
                        attrs: {
                          outlined: "",
                          small: "",
                          color: "white",
                          rounded: ""
                        },
                        on: { click: _vm.openReplyForm }
                      },
                      [_vm._v(" Reply ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "question-body white" },
        [
          _c("div", { staticClass: "question-detail" }, [
            _c("h3", { staticClass: "text-center mb-2" }, [_vm._v("Details")]),
            _vm._v(" " + _vm._s(_vm.question.details) + " ")
          ]),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showReplyForm,
                    expression: "showReplyForm"
                  }
                ],
                staticClass: "reply-form-container"
              },
              [
                _c("reply-form", {
                  attrs: {
                    closeForm: _vm.closeReplyForm,
                    isActive: _vm.showReplyForm,
                    questionId: _vm.question.id
                  },
                  on: { "submit:success": _vm.updateQuestionDetail }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-10 replies" },
            [
              _c("reply-list", {
                attrs: {
                  replyList: _vm.replyList,
                  isLoading: _vm.isLoadingQuestionDetail,
                  isManageable: _vm.isQuestionOwner,
                  questionId: _vm.question.id
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }