import { render, staticRenderFns } from "./Reply.vue?vue&type=template&id=973c78a4&scoped=true&"
import script from "./Reply.vue?vue&type=script&lang=js&"
export * from "./Reply.vue?vue&type=script&lang=js&"
import style0 from "./Reply.vue?vue&type=style&index=0&id=973c78a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "973c78a4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VOverlay,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('973c78a4')) {
      api.createRecord('973c78a4', component.options)
    } else {
      api.reload('973c78a4', component.options)
    }
    module.hot.accept("./Reply.vue?vue&type=template&id=973c78a4&scoped=true&", function () {
      api.rerender('973c78a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/questions/components/question-detail/replies/Reply.vue"
export default component.exports