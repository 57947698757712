<template>
  <div>
    <h3 class="reply-list-title">Replies</h3>
    <div v-if="isLoading">
      <v-progress-circular
        color="inqliPurple"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="mt-1">
        <toggle-button-group
          v-model="currentViewOption"
          tile
          group
          spaceBetween="mx-3"
          :options="listViewOptions"
        ></toggle-button-group>
      </div>
      <div>
        <v-fade-transition
          v-for="reply in currentReplyList"
          :key="reply.reply.id"
        >
          <reply
            :author="reply.author"
            :reply="reply.reply"
            :isManageable="isManageable"
            @update:reply="updateReplyList"
          />
        </v-fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import Reply from "./Reply.vue";
import ToggleButtonGroup from "../../../../app/components/buttons/ToggleButtonGroup.vue";
import { getReplyList } from "../../../utils/reply-request";
export default {
  name: "ReplyList",
  components: {
    reply: Reply,
    "toggle-button-group": ToggleButtonGroup,
  },
  props: {
    replyList: Object,
    replyUpdate: Function,
    questionId: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    isManageable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // this is to handle when isLoading is set to false when the component is created
    let currentViewOption = this.getInitialViewOption();
    return {
      currentViewOption,
      replies: this.replyList,
    };
  },
  watch: {
    replyList(value) {
      if (!this.isLoading) {
        this.replies = value;
        this.currentViewOption = this.getInitialViewOption();
      }
    },
  },
  computed: {
    listViewOptions() {
      const basedOptions = [
        {
          label: "Pending",
          value: "pending",
          badge: {
            text: this.replyPendingCount,
            color:
              this.replyPendingCount > 0 && this.isManageable
                ? "inqliRed"
                : "inqliGray",
          },
        },
        {
          label: "Accepted",
          value: "accepted",
          badge: {
            text: this.replyAcceptedCount,
            color: "inqliGray",
          },
        },
      ];
      if (this.isManageable) {
        basedOptions.push({
          label: "Hidden",
          value: "hidden",
          badge: {
            text: this.replyHiddenCount,
            color: "inqliGray",
          },
        });
      }
      return basedOptions;
    },
    currentReplyList() {
      if (this.isLoading) return null;
      return this.replies[this.currentViewOption];
    },
    replyPendingCount() {
      if (this.isLoading) return 0;
      return this.replies["pending"].length;
    },
    replyAcceptedCount() {
      if (this.isLoading) return 0;
      return this.replies["accepted"].length;
    },
    replyHiddenCount() {
      if (this.isLoading) return 0;
      return this.replies["hidden"].length;
    },
  },
  methods: {
    async updateReplyList() {
      try {
        const replyList = await getReplyList(this.questionId);
        this.replies = replyList;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while updating reply list."
        );
      }
    },
    getInitialViewOption() {
      let viewOption = "accepted";
      const pendingCount = this.replyList
        ? this.replyList["pending"].length
        : 0;
      if (pendingCount > 0 && this.isManageable) {
        viewOption = "pending";
      }
      return viewOption;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-reply-msg {
  color: $inqliNavy;
  font-size: 13px;
}
.reply-count {
  font-size: 12px;
  margin-bottom: 10px;
  color: $inqliDarkGray;
  font-weight: 600;
}
</style>
