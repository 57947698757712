import sendRequest from "../../app/utils/send-request-helper";

export const updateReply = async ({ id, body }) => {
  const response = await sendRequest({
    url: `/resources/replies/${id}`,
    method: "PUT",
    body,
  });
  return response;
};

export const updateReplyState = async ({ id, state }) => {
  const response = await sendRequest({
    url: `/resources/replies/${id}/state`,
    method: "PUT",
    body: {
      state,
    },
  });
  return response;
};

export const createReply = async ({ questionId, body }) => {
  const response = await sendRequest({
    url: `/resources/replies`,
    method: "POST",
    body: {
      ...body,
      state: "pending",
      question_id: questionId,
    },
  });
  return response;
};

export const getReplyList = async (questionId) => {
  const response = await sendRequest({
    url: `/resources/replies?question_id=${questionId}`,
    method: "GET",
  });
  return response;
};

export const getUrlTitle = async (url) => {
  const response = await sendRequest({
    url: `/services/title?url=${url}`,
    method: "GET",
  });
  return response;
};
