<template>
  <v-card flat class="question-detail-container">
    <span class="close-button">
      <inqli-icon @click="closeCard" v-if="closeCard">mdi-close</inqli-icon>
    </span>
    <div class="question-header">
      <v-img
        v-if="question.image_url"
        :src="question.image_url"
        class="question-image"
        gradient="180deg, rgba(0, 0, 0, 0) 0%, #031a4a 52.6%"
      ></v-img>
      <div class="question-header-info">
        <span class="question-type-container">{{ questionType }}</span>
        <h1 class="question-title">{{ question.title }}</h1>
        <div class="question-author">
          <router-link class="author-link" :to="authorLink"
            ><avatar size="medium" :image="author.avatar"
          /></router-link>
          <div class="author-info">
            <router-link :to="authorLink" class="author-link"
              ><span class="author-name">{{
                author.display_name
              }}</span></router-link
            >
            <span class="author-info-item" v-if="author.org">
              <v-icon x-small color="inqliGray"> mdi-home-city </v-icon>
              Works at {{ author.org }}</span
            >
          </div>
        </div>
        <div class="question-meta">
          <div
            v-for="item in questionMetaData"
            :key="item.value"
            class="question-meta-item"
          >
            <v-icon color="white" small>{{ item.icon }}</v-icon>
            <span class="ml-3">{{ item.value }}</span>
          </div>
        </div>
        <div class="question-action">
          <v-tooltip top open-on-hover v-if="isAuth">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                small
                color="inqliPurple"
                :class="{
                  'question-action-button': true,
                  'question-action-button-active': isLiked,
                }"
                rounded
                @click="toggleLikedQuestion"
              >
                <span
                  :class="{
                    'material-symbols-rounded': true,
                    fill: isLiked,
                  }"
                >
                  favorite
                </span>
              </v-btn>
            </template>
            <span>Like</span>
          </v-tooltip>
          <v-tooltip top open-on-hover v-if="isAuth">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                small
                color="inqliPurple"
                :class="{
                  'question-action-button': true,
                  'question-action-button-active': isBookmarked,
                }"
                rounded
                @click="toggleBookmarkedQuestion"
              >
                <span
                  :class="{
                    'material-symbols-rounded': true,
                    fill: isBookmarked,
                  }"
                >
                  {{ isBookmarked ? "bookmark" : "bookmark_add" }}
                </span>
              </v-btn>
            </template>
            <span>{{ isBookmarked ? "Unbookmark" : "Bookmark" }}</span>
          </v-tooltip>
          <v-btn
            outlined
            small
            color="inqliPurple"
            class="question-action-button"
            rounded
          >
            <share-link-dialog :small="true" :shareUrl="shareUrl" />
          </v-btn>
          <v-btn
            outlined
            small
            color="white"
            class="question-action-primary"
            rounded
            v-if="isAuth"
            @click="openReplyForm"
          >
            Reply
          </v-btn>
        </div>
      </div>
    </div>
    <div class="question-body white">
      <div class="question-detail">
        <h3 class="text-center mb-2">Details</h3>
        {{ question.details }}
      </div>
      <v-expand-transition>
        <div class="reply-form-container" v-show="showReplyForm">
          <reply-form
            :closeForm="closeReplyForm"
            :isActive="showReplyForm"
            :questionId="question.id"
            @submit:success="updateQuestionDetail"
          ></reply-form>
        </div>
      </v-expand-transition>
      <div class="mt-10 replies">
        <reply-list
          :replyList="replyList"
          :isLoading="isLoadingQuestionDetail"
          :isManageable="isQuestionOwner"
          :questionId="question.id"
        />
      </div>
    </div>
  </v-card>
</template>
<script>
import moment from "moment";
import InqliAvatar from "../../../app/components/avatars/Avatar.vue";
import ShareLinkDialog from "../../../app/components/dialogs/ShareLinkDialog.vue";
import InqliIcon from "../../../app/components/icons/InqliIcon.vue";
import ReplyList from "./replies/ReplyList.vue";
import ReplyForm from "./replies/ReplyForm.vue";
import {
  likeQuestion,
  unlikeQuestion,
  bookmarkQuestion,
  unBookmarkQuestion,
} from "../../utils/question-request.js";

export default {
  name: "QuestionDetail",
  components: {
    avatar: InqliAvatar,
    "share-link-dialog": ShareLinkDialog,
    "reply-list": ReplyList,
    "inqli-icon": InqliIcon,
    "reply-form": ReplyForm,
  },
  data() {
    return {
      isLoadingLikedQuestion: false,
      isLoadingBookmarkedQuestion: false,
      isLiked: this.questionDetail.is_liked,
      isBookmarked: this.questionDetail.is_bookmarked,
      showReplyForm: false,
    };
  },
  props: {
    questionDetail: Object,
    closeCard: Function,
    isLoadingQuestionDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isQuestionOwner() {
      return this.questionDetail.author.id === this.$store.state.user.username;
    },
    isAuth() {
      return this.$store.state.user.isLogin;
    },
    currentUserId() {
      return this.$store.state.user.username;
    },
    author() {
      return this.questionDetail.author;
    },
    question() {
      return this.questionDetail.question;
    },
    replyList() {
      return this.questionDetail.replies;
    },
    metadata() {
      return this.questionDetail.metadata;
    },
    questionMetaData() {
      return [
        {
          icon: "mdi-map-marker",
          value: this.location,
        },
        {
          icon: "mdi-calendar",
          value: this.createdAt,
        },
        {
          icon: "mdi-heart-outline",
          value: this.likes,
        },
        {
          icon: "mdi-forum",
          value: this.replies,
        },
      ];
    },
    replies() {
      let msg = `${this.metadata.reply_count}`;
      msg += this.metadata.reply_count <= 1 ? " reply" : " replies";
      return msg;
    },
    likes() {
      let msg = `${this.metadata.liked_count}`;
      msg += this.metadata.liked_count <= 1 ? " like" : " likes";
      return msg;
    },
    questionType() {
      return this.question.type.replace("iac-", "");
    },
    location() {
      let msg = this.question.location.city;
      msg += this.question.location.state
        ? `, ${this.question.location.state}`
        : "";
      return msg;
    },
    createdAt() {
      return moment
        .utc(this.question.created_on)
        .local()
        .format("MMM Do YYYY, h:mm A");
    },
    authorLink() {
      return `/users/${this.author.id}`;
    },
    shareUrl() {
      return `${window.location.origin}/questions/${this.question.id}`;
    },
  },
  methods: {
    updateQuestionDetail() {
      this.$emit("reload");
    },
    openReplyForm() {
      this.showReplyForm = true;
    },
    closeReplyForm() {
      this.showReplyForm = false;
    },
    async toggleLikedQuestion() {
      if (!this.isAuth) return;
      if (this.isLoadingLikedQuestion) return;
      // toggle liked question
      try {
        this.isLoadingLikedQuestion = true;
        if (this.isLiked) {
          this.isLiked = false;
          await unlikeQuestion({
            questionId: this.question.id,
            userId: this.currentUserId,
          });
        } else {
          this.isLiked = true;
          await likeQuestion({
            questionId: this.question.id,
            userId: this.currentUserId,
          });
        }
      } catch (error) {
        this.isLiked = !this.isLiked;
        this.$store.dispatch(
          "callAlert",
          "An error occurred while toggle liked question"
        );
      }
      this.isLoadingLikedQuestion = false;
    },
    async toggleBookmarkedQuestion() {
      if (!this.isAuth) return;
      if (this.isLoadingBookmarkedQuestion) return;
      // toggle bookmarked question
      try {
        // get true so it will not send request again
        this.isLoadingBookmarkedQuestion = true;
        if (this.isBookmarked) {
          // update first to have quick feedback to user
          this.isBookmarked = false;
          await unBookmarkQuestion({
            questionId: this.question.id,
            userId: this.currentUserId,
          });
          this.$emit("bookmark:delete", this.questionDetail);
        } else {
          this.isBookmarked = true;
          await bookmarkQuestion({
            questionId: this.question.id,
            userId: this.currentUserId,
          });
          this.$emit("bookmark:add", this.questionDetail);
        }
      } catch (e) {
        // if can not get response, set back to previous state and show error
        this.isBookmarked = !this.isBookmarked;
        this.$store.dispatch(
          "callAlert",
          "An error occurred while toggle bookmarked question"
        );
      }
      this.isLoadingBookmarkedQuestion = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-detail-container {
  background-color: transparent;
}
.question-header {
  position: relative;
  padding: 80px 32px 36px 32px;
  width: 100%;
  .question-header-info {
    z-index: 1;
    position: relative;
  }
  .question-title {
    text-align: left;
    margin-top: 5px;
    color: white;
    font-size: 20px;
  }
  .question-type-container {
    text-transform: uppercase;
    font-weight: 600;
    background-color: $inqliNavy !important;
    color: white;
    border: 2px solid #ffffff;
    font-size: 9px;
    padding: 2px 0;
    border-radius: 4px;
    display: block;
    width: 80px;
    margin-bottom: 10px;
  }
  .question-author {
    display: flex;
    margin: 5px auto;
    align-items: center;
    .author-link {
      font-size: 16px;
      text-decoration: none;
      color: white !important;
      font-weight: 700;
      .author-name {
        text-align: left !important;
        display: block;
        margin-left: 10px;
      }
    }
    .author-info-item {
      font-size: 11px;
      font-weight: 600;
      color: $inqliGray;
      display: block;
      text-align: left;
      margin-left: 10px;
    }
  }
  .question-meta {
    color: white !important;
    text-align: left;
    margin-top: 8px;
    font-size: 12px;
    .question-meta-item {
      margin-top: 3px;
      margin-left: 5px;
    }
  }
  .question-action {
    display: flex;
    justify-content: flex-end;
    .question-action-button {
      display: block;
      margin-right: 5px;
      background-color: white;
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .question-action-button-active {
      background-color: transparent !important;
    }
    .question-action-primary {
      display: block;
      background-color: $inqliPurple;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.question-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 16px 0 0;
}
.question-body {
  border-radius: 16px !important;
  margin-top: -16px;
  z-index: 3;
  padding: 16px 0;
  position: relative;
  .question-detail {
    text-align: left;
    white-space: break-spaces;
    font-size: 14px;
    color: $inqliNavy !important;
  }
  .replies,
  .question-detail {
    padding: 0 24px;
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 0 16px;
    }
  }
}
.question-button {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}

.share-button {
  position: absolute;
  right: -15px;
  top: 15px;
}
.fill {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.close-button {
  padding: 0 !important;
  z-index: 2;
  position: absolute;
  right: 5px;
  top: 5px;
}
.reply-form-container {
  background-color: darken(white, 2%);
  border-radius: 2px;
  border: 1px solid $inqliGray;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: -20px;
  transition: all 2s ease;
}
</style>
