<template>
  <div class="reply-container">
    <v-overlay :absolute="true" :value="isUpdating">
      <v-progress-circular
        color="inqliPurple"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="author-info">
      <router-link class="author-link" :to="authorLink">
        <avatar size="medium" :image="author.avatar" />
      </router-link>
      <div class="author-text-info">
        <div>
          <a class="author-link" :href="authorLink">
            <span class="author-name">{{ author.display_name }}</span></a
          >
          <span class="created-time">{{ timeAgo }}</span>
        </div>
        <span class="author-info-item" v-if="author.org">
          <v-icon small> mdi-home-city </v-icon>
          Works at {{ author.org }}</span
        >
      </div>
    </div>
    <div class="body">
      <div>
        <div class="section-content mt-2" v-if="reply.greeting">
          {{ reply.greeting }}
        </div>
        <div class="section-title">Advice and/or Instruction</div>
        <div class="section-content">
          {{ reply.advice }}
        </div>
      </div>
      <div v-if="questions && questions != ''">
        <div class="section-title">Follow-up questions</div>
        <div class="section-content">
          <ul>
            <li>
              {{ questions }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="resources && resources.length > 0">
        <div class="section-title">Resources</div>
        <div class="section-content">
          <ul>
            <li v-for="(item, index) in resources" :key="index">
              <a class="general-link resource-link" :href="item.url">{{
                item.title || item.url
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="isManageable">
      <div class="action-button-container" v-if="reply.state === 'pending'">
        <div>
          <inqli-button
            width="140px"
            @click="updateReplyStatus(actionButtonList.pending.approve.state)"
          >
            {{ actionButtonList.pending.approve.label }}</inqli-button
          >
        </div>
        <div>
          <outline-button
            width="140px"
            @click="updateReplyStatus(actionButtonList.pending.hide.state)"
            >{{ actionButtonList.pending.hide.label }}</outline-button
          >
        </div>
      </div>
      <div
        class="action-button-container"
        v-else-if="reply.state === 'accepted'"
      >
        <outline-button
          width="150px"
          @click="updateReplyStatus(actionButtonList.accepted.decline.state)"
          >{{ actionButtonList.accepted.decline.label }}</outline-button
        >
      </div>
      <div class="action-button-container" v-else>
        <inqli-button
          width="150px"
          @click="updateReplyStatus(actionButtonList.hidden['un-hide'].state)"
          >{{ actionButtonList.hidden["un-hide"].label }}</inqli-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../../../../app/components/avatars/Avatar.vue";
import Button from "../../../../app/components/buttons/Button.vue";
import OutlineButton from "../../../../app/components/buttons/OutlineButton.vue";
import moment from "moment";
import { updateReplyState } from "../../../utils/reply-request.js";
export default {
  name: "Reply",
  components: {
    avatar: Avatar,
    "inqli-button": Button,
    "outline-button": OutlineButton,
  },
  props: {
    reply: Object,
    author: Object,
    isManageable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timeAgo() {
      return moment.utc(this.reply.created_on).local().fromNow();
    },
    questions() {
      return this.reply.follow_up_questions
        ? this.reply.follow_up_questions
        : this.reply.questions.join(" ");
    },
    resources() {
      return this.reply.info;
    },
    authorLink() {
      return `/users/${this.author.id}`;
    },
    actionButtonList() {
      return {
        pending: {
          approve: {
            label: "Approve",
            state: "accepted",
          },
          hide: {
            label: "Hide",
            state: "hidden",
          },
        },
        accepted: {
          decline: {
            label: "Decline",
            state: "pending",
          },
        },
        hidden: {
          "un-hide": {
            label: "Un-hide",
            state: "pending",
          },
        },
      };
    },
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  methods: {
    async updateReplyStatus(state) {
      try {
        this.isUpdating = true;
        const updatedReply = await updateReplyState({
          id: this.reply.id,
          state,
        });
        this.$emit("update:reply", {
          prev: this.reply,
          updated: updatedReply,
        });
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while updating reply."
        );
      }
      this.isUpdating = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.reply-container {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid $inqliGray;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.author-info {
  text-decoration: none;
  color: $inqliNavy;
  display: flex;
  .v-icon {
    color: $inqliNavy;
    margin-right: 2px;
  }
  .author-link {
    text-decoration: none;
    color: $inqliNavy !important;
  }
}
.author-link:hover {
  color: $inqliButtonHover;
  .v-icon {
    color: $inqliButtonHover;
  }
}
.author-link:focus {
  color: $inqliButtonFocus;
  .v-icon {
    color: $inqliButtonFocus;
  }
}
.author-info-item {
  font-size: 11px;
  font-weight: 600;
  display: block;
  margin-top: 3px;
}
.author-text-info {
  margin-left: 10px;
}
.author-name {
  font-size: 14px;
  font-weight: 600;
}
.created-time {
  color: $inqliGray;
  margin-left: 10px;
  font-size: 11px;
  margin-bottom: 1px;
}
.body {
  color: $inqliNavy;
  .section-title {
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
  }
  .section-content {
    font-size: 14px;
    ul {
      margin-bottom: 5px;
    }
  }
}
.action-button-container {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  margin: 20px auto 10px auto;
}
.resource-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
@media screen and (max-width: $breakpoint-mobile) {
  .created-time {
    display: block;
    margin-left: 0;
  }
  .action-button-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
}
</style>
