var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "resource-input-container" },
    [
      _c(
        "v-col",
        { staticClass: "resource-input-item", attrs: { md: "5", cols: "12" } },
        [
          _c("v-text-field", {
            staticClass: "resource-input",
            attrs: {
              "aria-label": "resourceLink",
              placeholder: "Paste your link here"
            },
            model: {
              value: _vm.inputValue.url,
              callback: function($$v) {
                _vm.$set(_vm.inputValue, "url", $$v)
              },
              expression: "inputValue.url"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "resource-input-item", attrs: { md: "6", cols: "12" } },
        [
          _c("v-text-field", {
            staticClass: "resource-input",
            attrs: {
              "aria-label": "resourceTitle",
              placeholder: "Edit your title here"
            },
            model: {
              value: _vm.inputValue.title,
              callback: function($$v) {
                _vm.$set(_vm.inputValue, "title", $$v)
              },
              expression: "inputValue.title"
            }
          })
        ],
        1
      ),
      _c(
        "v-icon",
        {
          staticClass: "resource-remove-button",
          attrs: { "aria-label": "resource-remove" },
          on: { click: _vm.removeResource }
        },
        [_vm._v("mdi-close-circle-outline")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }