import { render, staticRenderFns } from "./QuestionDetail.vue?vue&type=template&id=290e61dc&scoped=true&"
import script from "./QuestionDetail.vue?vue&type=script&lang=js&"
export * from "./QuestionDetail.vue?vue&type=script&lang=js&"
import style0 from "./QuestionDetail.vue?vue&type=style&index=0&id=290e61dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290e61dc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VExpandTransition,VIcon,VImg,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('290e61dc')) {
      api.createRecord('290e61dc', component.options)
    } else {
      api.reload('290e61dc', component.options)
    }
    module.hot.accept("./QuestionDetail.vue?vue&type=template&id=290e61dc&scoped=true&", function () {
      api.rerender('290e61dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/questions/components/question-detail/QuestionDetail.vue"
export default component.exports