var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reply-container" },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: true, value: _vm.isUpdating } },
        [
          _c("v-progress-circular", {
            attrs: { color: "inqliPurple", indeterminate: "" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "author-info" },
        [
          _c(
            "router-link",
            { staticClass: "author-link", attrs: { to: _vm.authorLink } },
            [
              _c("avatar", {
                attrs: { size: "medium", image: _vm.author.avatar }
              })
            ],
            1
          ),
          _c("div", { staticClass: "author-text-info" }, [
            _c("div", [
              _c(
                "a",
                { staticClass: "author-link", attrs: { href: _vm.authorLink } },
                [
                  _c("span", { staticClass: "author-name" }, [
                    _vm._v(_vm._s(_vm.author.display_name))
                  ])
                ]
              ),
              _c("span", { staticClass: "created-time" }, [
                _vm._v(_vm._s(_vm.timeAgo))
              ])
            ]),
            _vm.author.org
              ? _c(
                  "span",
                  { staticClass: "author-info-item" },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(" mdi-home-city ")
                    ]),
                    _vm._v(" Works at " + _vm._s(_vm.author.org))
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("div", { staticClass: "body" }, [
        _c("div", [
          _vm.reply.greeting
            ? _c("div", { staticClass: "section-content mt-2" }, [
                _vm._v(" " + _vm._s(_vm.reply.greeting) + " ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "section-title" }, [
            _vm._v("Advice and/or Instruction")
          ]),
          _c("div", { staticClass: "section-content" }, [
            _vm._v(" " + _vm._s(_vm.reply.advice) + " ")
          ])
        ]),
        _vm.questions && _vm.questions != ""
          ? _c("div", [
              _c("div", { staticClass: "section-title" }, [
                _vm._v("Follow-up questions")
              ]),
              _c("div", { staticClass: "section-content" }, [
                _c("ul", [
                  _c("li", [_vm._v(" " + _vm._s(_vm.questions) + " ")])
                ])
              ])
            ])
          : _vm._e(),
        _vm.resources && _vm.resources.length > 0
          ? _c("div", [
              _c("div", { staticClass: "section-title" }, [
                _vm._v("Resources")
              ]),
              _c("div", { staticClass: "section-content" }, [
                _c(
                  "ul",
                  _vm._l(_vm.resources, function(item, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "a",
                        {
                          staticClass: "general-link resource-link",
                          attrs: { href: item.url }
                        },
                        [_vm._v(_vm._s(item.title || item.url))]
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm.isManageable
        ? _c("div", [
            _vm.reply.state === "pending"
              ? _c("div", { staticClass: "action-button-container" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "inqli-button",
                        {
                          attrs: { width: "140px" },
                          on: {
                            click: function($event) {
                              return _vm.updateReplyStatus(
                                _vm.actionButtonList.pending.approve.state
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.actionButtonList.pending.approve.label)
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "outline-button",
                        {
                          attrs: { width: "140px" },
                          on: {
                            click: function($event) {
                              return _vm.updateReplyStatus(
                                _vm.actionButtonList.pending.hide.state
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.actionButtonList.pending.hide.label)
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm.reply.state === "accepted"
              ? _c(
                  "div",
                  { staticClass: "action-button-container" },
                  [
                    _c(
                      "outline-button",
                      {
                        attrs: { width: "150px" },
                        on: {
                          click: function($event) {
                            return _vm.updateReplyStatus(
                              _vm.actionButtonList.accepted.decline.state
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.actionButtonList.accepted.decline.label)
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "action-button-container" },
                  [
                    _c(
                      "inqli-button",
                      {
                        attrs: { width: "150px" },
                        on: {
                          click: function($event) {
                            return _vm.updateReplyStatus(
                              _vm.actionButtonList.hidden["un-hide"].state
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.actionButtonList.hidden["un-hide"].label)
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }