var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "reply-list-title" }, [_vm._v("Replies")]),
    _vm.isLoading
      ? _c(
          "div",
          [
            _c("v-progress-circular", {
              attrs: { color: "inqliPurple", indeterminate: "" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("toggle-button-group", {
                attrs: {
                  tile: "",
                  group: "",
                  spaceBetween: "mx-3",
                  options: _vm.listViewOptions
                },
                model: {
                  value: _vm.currentViewOption,
                  callback: function($$v) {
                    _vm.currentViewOption = $$v
                  },
                  expression: "currentViewOption"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            _vm._l(_vm.currentReplyList, function(reply) {
              return _c(
                "v-fade-transition",
                { key: reply.reply.id },
                [
                  _c("reply", {
                    attrs: {
                      author: reply.author,
                      reply: reply.reply,
                      isManageable: _vm.isManageable
                    },
                    on: { "update:reply": _vm.updateReplyList }
                  })
                ],
                1
              )
            }),
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }