var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reply-form" },
    [
      _vm.closeForm
        ? _c(
            "v-icon",
            {
              staticClass: "close-button",
              attrs: { color: "inqliGray", dense: "" },
              on: { click: _vm.closeForm }
            },
            [_vm._v("mdi-close")]
          )
        : _vm._e(),
      _c("h3", [_vm._v("Your Reply")]),
      _c("div", { staticClass: "clocker" }, [
        _vm._v("Time spent: " + _vm._s(_vm.timeString))
      ]),
      _c(
        "div",
        [
          _c(
            "div",
            [
              _c("p", { staticClass: "form-item-label" }, [
                _vm._v("Your greeting")
              ]),
              _c(
                "inqli-text-area",
                {
                  attrs: { rows: "2", "aria-label": "greeting" },
                  model: {
                    value: _vm.greeting,
                    callback: function($$v) {
                      _vm.greeting = $$v
                    },
                    expression: "greeting"
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "Your greeting can impact the recipient's perception of you and it sets the tone for your interaction. Go ahead and type a friendly greeting here."
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", { staticClass: "form-item-label" }, [
                _vm._v("Advice and/or Instruction")
              ]),
              _c(
                "inqli-text-area",
                {
                  attrs: {
                    rows: "2",
                    hint: _vm.adviceHint,
                    "persistent-hint": "",
                    "aria-label": "advice"
                  },
                  model: {
                    value: _vm.advice,
                    callback: function($$v) {
                      _vm.advice = $$v
                    },
                    expression: "advice"
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      "Include any advice based on your knowledge and experience. Being clear and concise is helpful!"
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c("div", [
            _c("p", { staticClass: "form-item-label" }, [_vm._v("Resources")]),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-row",
                  { staticClass: "resource-input-container" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "resource-input-item label",
                        attrs: { md: "5", cols: "12" }
                      },
                      [
                        _c("p", { staticClass: "form-item-label" }, [
                          _vm._v("Link")
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "resource-input-item label",
                        attrs: { md: "6", cols: "12" }
                      },
                      [
                        _c("p", { staticClass: "form-item-label" }, [
                          _vm._v("Title")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._l(_vm.resources, function(item, index) {
                  return _c(
                    "div",
                    { key: item.key },
                    [
                      _c("resource-input", {
                        attrs: { value: _vm.resources[index] },
                        on: {
                          input: function($event) {
                            return _vm.updateResource($event, index)
                          },
                          remove: function($event) {
                            return _vm.removeResourceItem(index)
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isResourceValid,
                    expression: "isResourceValid"
                  }
                ],
                staticClass: "resource-add-button"
              },
              [
                _c(
                  "text-button",
                  {
                    attrs: { noPadding: true },
                    on: { click: _vm.addResourceItem }
                  },
                  [_vm._v(" + Add additional resource")]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "status-bar-container" }, [
            _c(
              "div",
              { staticClass: "status-bar rounded-pill" },
              [
                _c(
                  "v-progress-linear",
                  {
                    staticClass: "rounded-pill",
                    attrs: {
                      color: "inqliGreen",
                      "background-color": "transparent",
                      height: "18",
                      value: _vm.scoreRatioPercentage
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.scoreFeedback))])]
                )
              ],
              1
            )
          ]),
          _c(
            "inqli-button",
            {
              staticClass: "mt-8",
              attrs: {
                width: "160px",
                disabled: !_vm.isSubmittable,
                isProcess: _vm.isSubmitting
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Send Reply")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }